import "./App.scss";
import { useState, useEffect } from "react";
import {
  ConfigProvider,
  Button,
  Progress,
  Select,
  Input,
  Tag,
  message,
} from "antd";
import levelImg from "./assets/img/level.svg";
import borderImg from "./assets/img/border.svg";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "./lang/index";
import { ethers } from "ethers";
import Game from "./hardhat/artifacts/contracts/game.sol/Game.json";
import IERC20 from "./hardhat/artifacts/contracts/game.sol/IERC20.json";
import ETNDToken from "./hardhat/artifacts/contracts/Usdt.sol/TetherToken.json";
import Decimal from "decimal.js";
import copy from "copy-to-clipboard";


//1k:0xDB1aEC01adc669f4115160BFC1343F1E9941354D
//1w:0x538B6bFf3A54e3420a8d96F78262E112c0CbcBE8
//TEST:0x99de27a36f0eB7bffB402ae4792e25C6554da72f
//1000：0x1b3280203A7DC1FAEC6b4c455BD15Fc660f98903

let ChainInfo = 131419; //131419
let ContractAddr = "0x1b3280203A7DC1FAEC6b4c455BD15Fc660f98903";
let USDT = "0x720416063b4cFE8300fFFACC478009657d85bb90";
let EH = "0xCb0529ae184227573217c3482182dDCdD14Ac830";

let USDTContract;
let EHContract;
let Accounts;
let ChainInfos;
let GameContract;
let Providers;
let addr_;

export default function App() {
  let timer1 = null;
  const [locale, setLocale] = useState(localStorage.getItem("locale") || "zh");
  const [providers, setProviders] = useState(null);
  const [account, setAccounts] = useState();
  const [Account, setAccount] = useState();
  const [ConnectWalletLoading, setConnectWalletLoading] = useState(false);
  const [RoundListInfo, setRoundListInfo] = useState([]);
  const [MyBalance, setMyBalance] = useState([0, 0, 0, 0, 0]);
  const [Round, setRound] = useState();
  const [GameInfo, setGameInfo] = useState([0, 0, 0, 0, 0, 0]);
  const [SubordinateList, setSubordinateList] = useState([]);
  const [BetV, setBetV] = useState();
  const [timeS, setTime] = useState(["00", "00", "00"]);
  const [UserInfos, setUserInfos] = useState(["", 0, 0, 0, 0]);
  const [BindRefAddr, setBindRefAddr] = useState();
  const [RefURL, setRefURL] = useState();
  const [IncomeInfo, setIncomeInfo] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [Maxproportion, setMaxproportion] = useState(2);
  const [OnChangeRound, setOnChangeRound] = useState();
  const [GameStatus, setGameStatus] = useState("---");

  const [BetLoading, setBetLoading] = useState(false);
  const [BindRefLoading, setBindRefLoading] = useState(false);
  const [receivePledgeRoundsLoading, setReceivePledgeRoundsLoading] =
    useState(false);
  const [ReceiveBaseIncomeLoading, setReceiveBaseIncomeLoading] =
    useState(false);
  const [ReceiveAdditionalIncomeLoading, setReceiveAdditionalIncomeLoading] =
    useState(false);
  const [
    GetRoundsToMarginCallValueLoading,
    setGetRoundsToMarginCallValueLoading,
  ] = useState(false);

  const [BindRefDisabled, setBindRefDisabled] = useState();
  useEffect(() => {
    addr_ = window.location.hash.substring(1, window.location.hash.length);
  }, []);

  // 切换语言
  const onToggleLocale = () => {
    const localeVal = locale === "zh" ? "en" : "zh";
    localStorage.setItem("locale", localeVal);
    setLocale(localeVal);
  };

  let ConnectWallet = async () => {
    try {
      if (isMetaMaskInstalled()) {
        if (ChainInfos && ChainInfo == ChainInfos.chainId) {
          return;
        }
        setConnectWalletLoading(true);
        Providers = new ethers.providers.Web3Provider(window.ethereum);
        setProviders(Providers);

        await GetAccount();
        await chainChanged();
        setConnectWalletLoading(false);
        await GetGameInfo();
        setRefURL(window.location.host + "/#" + Accounts);
        Providers.on("block", async () => {
          await GetGameInfo();
        });
        await window.ethereum.on("chainChanged", async function () {
          Providers = new ethers.providers.Web3Provider(window.ethereum);
          setProviders(Providers);
          ChainInfos = await Providers.getNetwork();
          console.log("chainChanged", ChainInfos.chainId);
          setAccount(
            Accounts.substring(0, 10) +
            "..." +
            Accounts.substring(33, 42).toString()
          );
          await chainChanged();
          await GetGameInfo();
        });
        window.ethereum.on("accountsChanged", async function () {
          await GetAccount();
          setOnChangeRound();
          await GetGameInfo();
        });
      } else {
        message.warning(
          locale == "zh" ? "没有检测到钱包插件!" : "Please install MetaMask"
        );
        return;
      }
    } catch (err) {
      setConnectWalletLoading(false);
      console.log(err);
    }
  };

  let chainChanged = async () => {
    try {
      Providers = new ethers.providers.Web3Provider(window.ethereum);
      setProviders(Providers);
      ChainInfos = await Providers.getNetwork();
      //console.log('ChainInfos.chainId', ChainInfos.chainId, '0x' + ChainInfo.toString(16));
      if (ChainInfos.chainId !== ChainInfo) {
        setAccount(
          locale == "zh"
            ? "请选择正确的网络!"
            : "Please select the correct network !"
        );
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x" + ChainInfo.toString(16) }],
        });
      } else {
        setAccount(
          Accounts.substring(0, 10) +
          "..." +
          Accounts.substring(33, 42).toString()
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  let GetAccount = async () => {
    try {
      await window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => {
          Accounts = accounts[0];
          setAccounts(Accounts);
          setAccount(
            Accounts.substring(0, 10) +
            "..." +
            Accounts.substring(33, 42).toString()
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  let GetGameInfo = async () => {
    try {
      USDTContract = new ethers.Contract(
        USDT,
        ETNDToken.abi,
        Providers.getSigner()
      );
      EHContract = new ethers.Contract(EH, IERC20.abi, Providers.getSigner());

      GameContract = new ethers.Contract(
        ContractAddr,
        Game.abi,
        Providers.getSigner()
      );
      let _round = await GameContract.round();
      setRound(_round.toString());
      let _gameinfo = await GameContract.gameInfo(_round);

      setGameInfo([
        _gameinfo[0],
        _gameinfo[1].toString(),
        _gameinfo[2].toString(),
        _gameinfo[3].toString(),
        _gameinfo[4],
        _gameinfo[5],
        _gameinfo[6],
      ]);
      // let { _Roundlist, InitValue, value, startTime, initStatus, status, marginCall } = await GameContract.gameInfo(_round);
      let _gameinfos = await GameContract.pledgeRoundsIncome(9);
      console.log(_gameinfos[0].toString(), _gameinfos[1].toString());
      //console.log(_gameinfo);
      // if (_gameinfo[0].length >= 1) {
      //   //console.log(_gameinfo[0][0]);
      //   //setOnChangeRound(_gameinfo[0][0].toString());
      //   // setDef();
      //   //Def = _gameinfo[0][0].toString()
      //   showRoundInfo(_gameinfo[0][0].toString());
      // }
      setRoundListInfo(
        _gameinfo[0].map((item) => ({
          label: item.toString(),
          value: item.toString(),
        }))
      );

      let _value = await GameContract.getUserAssets(Accounts);
      setMyBalance([
        _value[0],
        _value[1],
        _value[2],
        _value[3],
        _value[4],
        _value[5],
      ]);
      let _subordinateList = await GameContract.getSubordinate(
        Accounts.toString()
      );
      //console.log(_subordinateList)
      setSubordinateList(_subordinateList);

      let UserInfo = await GameContract.getUserInfo(Accounts);
      setUserInfos(UserInfo);
      //setUserInfos(UserInfo[0], UserInfo[1], UserInfo[2], UserInfo[3], UserInfo[4]);
      //console.log(UserInfo[0], UserInfo[1], UserInfo[2], UserInfo[3], UserInfo[4])
      if (UserInfo[0] != "0x0000000000000000000000000000000000000000") {
        setBindRefDisabled(true);
        setBindRefAddr(UserInfo[0]);
      } else {
        setBindRefAddr(addr_);
      }
      let _timeInterval = await GameContract.timeInterval(); //86400
      let _Maxproportion = await GameContract.Maxproportion();
      setMaxproportion(_Maxproportion);
      clearInterval(timer1);
      timer1 = setInterval(async function () {
        let now = parseInt(Date.now() / 1000);
        //let now = parseInt(Date.now());
        //console.log(_gameinfo[3].toString() / 1000 + _timeInterval, now)
        //console.log()

        let a_ = new Decimal(_gameinfo[3].toString())
          .add(new Decimal(_timeInterval.toString()))
          .sub(new Decimal(now.toString()));
        //console.log(_gameinfo[3].toString(), now, a_.toString())
        if (a_ <= 0) {
          setTime(leftTimer(0));
        } else {
          setTime(leftTimer(a_));
        }
        //setTime(leftTimer(_gameinfo[3].toString() * 1 + _timeInterval - now))
        //console.log(leftTimer(_gameinfo[3].toString() * 1 + 86400 - now))
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  let bet = async (_value) => {
    try {
      if (!_value || _value <= 0) {
        return;
      }
      if (!Providers) {
        message.warning("请先连接钱包!");
        return;
      }
      if (String(_value).indexOf(".") > -1) {
        message.warning("只能整数购买");
        return;
      }
      let _values = _value * 1000000;
      setBetLoading(true);
      let value__ = await USDTContract.allowance(Accounts, ContractAddr);
      if (!value__ || value__ > 0) {
        let app_s = await USDTContract.approve(ContractAddr, 0);
        await app_s.wait();
      }
      let app_ = await USDTContract.approve(ContractAddr, _values);
      await app_.wait();
      let eh = new Decimal(_values.toString())
        .mul(new Decimal("1000000000000"))
        .mul(new Decimal("2"))
        .div(new Decimal("100"));
      let app_1 = await EHContract.approve(ContractAddr, eh.toString());
      await app_1.wait();
      let tx = await GameContract.Bet(_values);

      await tx.wait();
      setBetLoading(false);
    } catch (err) {
      setBetLoading(false);
      console.log(err);
    }
  };
  //爆仓后可领取的一次性本金
  let receivePledgeRounds = async () => {
    try {
      if (!OnChangeRound) {
        return;
      }
      if (!Providers) {
        message.warning("请先连接钱包!");
        return;
      }

      setReceivePledgeRoundsLoading(true);

      let tx = await GameContract.ReceivePledgeRounds(OnChangeRound);

      await tx.wait();
      setReceivePledgeRoundsLoading(false);
    } catch (err) {
      setReceivePledgeRoundsLoading(false);
      console.log(err);
    }
  };

  //初始目标完成后可领取的本金
  let ReceiveBaseIncome = async () => {
    try {
      if (!OnChangeRound) {
        return;
      }
      if (!Providers) {
        message.warning("请先连接钱包!");
        return;
      }
      if (new Decimal(IncomeInfo[5].toString()).lessThanOrEqualTo(new Decimal("0"))) {
        return;
      }
      setReceiveBaseIncomeLoading(true);

      let tx = await GameContract.ReceiveBaseIncome(OnChangeRound);

      await tx.wait();
      setReceiveBaseIncomeLoading(false);
    } catch (err) {
      setReceiveBaseIncomeLoading(false);
      console.log(err);
    }
  };

  //当期完成后领取额外收益
  let ReceiveAdditionalIncome = async () => {
    try {
      if (!OnChangeRound) {
        return;
      }
      console.log("OnChangeRound", OnChangeRound);
      if (!Providers) {
        message.warning("请先连接钱包!");
        return;
      }
      if (new Decimal(IncomeInfo[6].toString()).lessThanOrEqualTo(new Decimal("0"))) {
        return;
      }
      setReceiveAdditionalIncomeLoading(true);

      let tx = await GameContract.ReceiveAdditionalIncome(OnChangeRound);

      await tx.wait();
      setReceiveAdditionalIncomeLoading(false);
    } catch (err) {
      setReceiveAdditionalIncomeLoading(false);
      console.log(err);
    }
  };

  //当期完成后领取额外收益
  let GetRoundsToMarginCallValue = async () => {
    try {
      if (!OnChangeRound) {
        return;
      }
      console.log("OnChangeRound", OnChangeRound);
      if (!Providers) {
        message.warning("请先连接钱包!");
        return;
      }
      if (new Decimal(IncomeInfo[4].toString()).lessThanOrEqualTo(new Decimal("0")) || new Decimal(IncomeInfo[3].toString()).lessThanOrEqualTo(new Decimal("0"))) {
        return;
      }
      setGetRoundsToMarginCallValueLoading(true);

      let tx = await GameContract.GetRoundsToMarginCallValue(OnChangeRound);

      await tx.wait();
      setGetRoundsToMarginCallValueLoading(false);
    } catch (err) {
      setGetRoundsToMarginCallValueLoading(false);
      console.log(err);
    }
  };

  let BindRef = async (_addr) => {
    try {
      if (!_addr) {
        locale == "zh"
          ? message.warning("地址不能为空")
          : message.warning("Address cannot be empty");
        return;
      }
      if (!Providers) {
        message.warning("请先连接钱包!");
        return;
      }
      setBindRefLoading(true);
      let tx = await GameContract.BindRf(_addr);
      await tx.wait();

      setBindRefLoading(false);
    } catch (err) {
      locale == "zh"
        ? message.warning("邀请地址未注册")
        : message.warning("The invitation address is not registered");
      setBindRefLoading(false);
      console.log(err);
    }
  };

  let showRoundInfo = async (_round) => {
    try {
      if (!_round) {
        return;
      }
      if (!Providers) {
        message.warning("请先连接钱包!");
        return;
      }
      let _IncomeInfo = await GameContract.showIncome(_round);
      setOnChangeRound(_round);
      //console.log("_IncomeInfo", _IncomeInfo[2].toString())
      setIncomeInfo(_IncomeInfo);
      //console.log(_round, "----------------------");
      let _gameinfos = await GameContract.gameInfo(_round);
      //console.log(_gameinfos[4],_gameinfos[5],_gameinfos[6]);
      if (_gameinfos[6]) {
        locale == "zh"
          ? setGameStatus("已爆仓")
          : setGameStatus("Exploded warehouse");
      } else if (_gameinfos[4] && _gameinfos[5]) {
        locale == "zh" ? setGameStatus("已完成") : setGameStatus("Completed");
      } else if (_gameinfos[4]) {
        locale == "zh"
          ? setGameStatus("初始目标已完成")
          : setGameStatus("Initial target completed");
      }
    } catch (err) {
      console.log(err);
    }
  };

  function leftTimer(timeData) {
    var leftTime = timeData;
    var hours = parseInt(leftTime / 60 / 60);
    var minutes = parseInt((leftTime / 60) % 60);
    var seconds = parseInt(leftTime % 60);
    //补零
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    if (leftTime <= 0) {
      return ["00", "00", "00"];
    } else {
      return [hours.toString(), minutes.toString(), seconds.toString()];
    }
  }

  function copyCot(cot) {
    copy(cot);
    message.success("复制成功");
  }

  function isMetaMaskInstalled() {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#8153FF" } }}>
      <IntlProvider key={locale} locale={locale} messages={messages[locale]}>
        <div className="app h-100 bg-black color-white flex-vertical">
          <header className="flex-header flex-item align-items-center bg-dark px-3">
            <div className="flex-label text-zero">
              <img
                className="logo"
                src={require("./assets/img/logo.png")}
                alt=""
              />
            </div>
            <div className="flex-content flex-item align-items-center justify-content-end py-2">
              <Button
                className="ant-btn-primary"
                onClick={ConnectWallet}
                loading={ConnectWalletLoading}
              >
                {Account ? Account : <FormattedMessage id="link" />}
              </Button>
              <Button
                onClick={onToggleLocale}
                className="ant-btn-default ml-3 flex-center-center"
              >
                {locale === "zh" ? (
                  <svg
                    t="1672297989467"
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="5159"
                    width="1.8rem"
                    height="1.8rem"
                  >
                    <path
                      d="M782 912H242c-71.68 0-130-58.32-130-130V242c0-71.68 58.32-130 130-130h540c71.68 0 130 58.32 130 130v540c0 71.68-58.32 130-130 130zM242 172c-38.59 0-70 31.41-70 70v540c0 38.59 31.41 70 70 70h540c38.59 0 70-31.41 70-70V242c0-38.59-31.41-70-70-70H242z"
                      p-id="5160"
                      fill="#ffffff"
                    ></path>
                    <path
                      d="M718.2 390.43c-1.74-7.96-5.1-14.25-10.01-18.7-4.8-4.35-11.29-7.2-19.31-8.47-6.61-1.03-15.03-1.56-25.05-1.56H539.5v-61.48c0-9.62-2.64-16.88-7.9-21.61-4.92-4.39-11.92-6.62-20.8-6.62-9.66 0-16.79 2.17-21.78 6.64-5.22 4.68-7.88 11.94-7.88 21.59v61.48H359.22c-10.36 0-18.67 0.7-25.39 2.15-7.59 1.66-13.81 4.63-18.49 8.84-4.99 4.53-8.29 10.81-9.81 18.68-1.26 6.44-1.86 14.27-1.86 23.97V567.4c0 9.39 0.61 17.02 1.87 23.3 1.48 7.46 4.53 13.64 9.03 18.37 4.61 4.84 11.04 8.16 19.08 9.84 6.76 1.42 15.13 2.13 25.58 2.13h121.92v103.21c0 8.86 1.84 15.42 5.64 20.04 5.7 6.91 16.16 7.71 23.54 7.71 10.44 0 17.82-2.6 22.57-7.9 4.38-4.94 6.61-11.44 6.61-19.36v-103.7h124.8c10.44 0 18.81-0.7 25.54-2.13 7.98-1.66 14.43-4.92 19.18-9.66s7.93-10.99 9.45-18.52c1.26-6.3 1.87-13.94 1.87-23.32V415.83c-0.02-10.37-0.72-18.66-2.15-25.4z m-55.26 27.97v145.15c0 0.2 0 0.39-0.01 0.57H539.5V418.6l123.44-0.2z m-181.8 0.22v145.5H361.07v-145.5h120.07z m-121.92-10v9.84c-0.08 0.06-0.17 0.12-0.25 0.18l0.25-10.02z"
                      p-id="5161"
                      fill="#ffffff"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    t="1672298035132"
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="6186"
                    width="1.8rem"
                    height="1.8rem"
                  >
                    <path
                      d="M782 912H242c-71.68 0-130-58.32-130-130V242c0-71.68 58.32-130 130-130h540c71.68 0 130 58.32 130 130v540c0 71.68-58.32 130-130 130zM242 172c-38.59 0-70 31.41-70 70v540c0 38.59 31.41 70 70 70h540c38.59 0 70-31.41 70-70V242c0-38.59-31.41-70-70-70H242z"
                      p-id="6187"
                      fill="#ffffff"
                    ></path>
                    <path
                      d="M455.49 621.78c-3.97-1.08-8.51-1.71-13.51-1.9-4.32-0.15-8.84-0.21-13.6-0.21h-100.5v-86.64h107.87c9.01 0 15.72-1.65 20.48-4.99 4.09-2.86 8.98-8.65 8.98-20.14 0-9.2-2.82-16.12-8.37-20.56-4.91-3.93-11.8-5.86-21.09-5.86H327.89V404.8h112.2c8.39 0 14.92-1.69 19.96-5.14 4.34-2.94 9.51-8.88 9.5-20.98-0.48-9.18-3.66-15.97-9.53-20.18-4.96-3.53-11.48-5.25-19.93-5.25H302.76c-10.47 0-18.01 2.31-23.07 7.09-5.17 4.93-7.69 12.25-7.69 22.36v259.06c0 11.11 3.15 19.06 9.36 23.65 5.28 3.91 12.28 5.82 21.4 5.82h139.93c9.38 0 16.37-1.54 21.44-4.74 4.45-2.83 9.75-8.84 9.75-21.26 0-8.19-3.11-13.24-5.7-16.01-3.26-3.53-7.52-6.03-12.69-7.44zM745.3 356.36c-4.74-5.25-11.96-7.91-21.46-7.91-9.85 0-17.18 3.02-21.79 8.99-3.95 5.1-5.94 11.4-5.94 18.74v188.1L559.8 364.97c-2.85-3.45-5.68-6.6-8.5-9.41-3.24-3.24-9.06-7.11-18.51-7.11-11.87 0-17.89 5.16-20.85 9.5-3.42 4.99-5.14 11.13-5.14 18.23v271.21c0 7.47 2.22 13.94 6.59 19.23 3.36 4.06 9.75 8.93 21.13 8.93 11.23 0 17.72-4.74 21.17-8.72 4.64-5.33 6.99-11.89 6.99-19.44v-184.6l135.35 195.08c3.18 4.61 6.73 8.5 10.59 11.63 5.01 4.02 10.86 6.05 17.39 6.05 11.9 0 17.92-5.18 20.85-9.5 3.42-4.99 5.14-11.13 5.14-18.23V376.19c0-8.19-2.24-14.85-6.7-19.83z"
                      p-id="6188"
                      fill="#ffffff"
                    ></path>
                  </svg>
                )}
              </Button>
            </div>
          </header>
          <main className="flex-content px-3 pb-4">
            <section className="round-lg overlflow-hidden mt-3">
              <img
                className="w-100"
                src={require("./assets/img/banner.png")}
                alt=""
              />
            </section>
            <section className="mt-3">
              <div className="flex-center-center">
                <div className="position-relative">
                  <img src={levelImg} alt="" />
                  <div className="level-text text-title text-bold">{Round}</div>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <span className="color-gray">
                      <FormattedMessage id="current" />
                    </span>
                    <span>{GameInfo[2] / 1000000} USDT</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="color-gray">
                      <FormattedMessage id="target" />
                    </span>
                    <span>{GameInfo[1] / 1000000} USDT</span>
                  </div>
                </div>
                <Progress
                  className="warning"
                  percent={((GameInfo[2] / GameInfo[1]) * 100).toFixed(2)}
                  showInfo={true}
                />
              </div>

              <div className="flex-center-center mt-3">
                <div className="d-flex color-gray text-sm">
                  <div className="text-box">
                    <FormattedMessage id="day" />
                  </div>
                  <div className="text-hidden">:</div>
                  <div className="text-box">
                    <FormattedMessage id="hour" />
                  </div>
                  <div className="text-hidden">:</div>
                  <div className="text-box">
                    <FormattedMessage id="minute" />
                  </div>
                  <div className="text-hidden">:</div>
                  <div className="text-box">
                    <FormattedMessage id="second" />
                  </div>
                </div>
              </div>
              <div className="flex-center-center mt-1">
                <div className="d-flex align-items-center">
                  <div className="time-box">00</div>
                  <div>:</div>
                  <div className="time-box">{timeS[0]}</div>
                  <div>:</div>
                  <div className="time-box">{timeS[1]}</div>
                  <div>:</div>
                  <div className="time-box">{timeS[2]}</div>
                </div>
              </div>
              <div className="bg-common border-b border-l border-r round-lg px-3 py-4">
                <div className="flex-item">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="lowest" />
                  </div>
                  <div className="flex-content text-right">1 USDT</div>
                </div>
                <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="highest" />
                  </div>
                  <div className="flex-content text-right">
                    {((GameInfo[1] * Maxproportion) / 100 / 1000000).toFixed(0)}{" "}
                    USDT
                  </div>
                </div>
                <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="ticket" />
                  </div>
                  <div className="flex-content text-right">
                    {!BetV
                      ? 0
                      : ((BetV * 2) / 100).toFixed(0) <= 1
                        ? 1
                        : ((BetV * 2) / 100).toFixed(0)}{" "}
                    EH
                  </div>
                </div>
                <div className="mt-4">
                  <Input
                    onChange={async (event) => {
                      if (parseFloat(event.target.value).toString() == "NaN") {
                        return;
                      }
                      setBetV(event.target.value);
                    }}
                  />
                </div>
                <div className="mt-3">
                  <Button
                    className="w-100 ant-btn-warning"
                    loading={BetLoading}
                    onClick={async () => {
                      bet(BetV);
                    }}
                  >
                    <FormattedMessage id="invest" />
                  </Button>
                </div>
              </div>
            </section>
            <section className="bg-common border round-lg px-3 py-4 mt-3">
              <h4 className="d-flex align-items-center">
                <img src={borderImg} alt="" />
                <span className="ml-3">
                  <FormattedMessage id="property" />
                </span>
              </h4>
              <div className="mt-4">
                <div className="flex-item">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="usdt" />
                  </div>
                  <div className="flex-content text-right">
                    {(MyBalance[0] / 1000000).toFixed(6)}
                  </div>
                </div>
                <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="eh" />
                  </div>
                  <div className="flex-content text-right">
                    {(MyBalance[1] / 1000000000000000000).toFixed(6)}
                  </div>
                </div>
                <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="ebw" />
                  </div>
                  <div className="flex-content text-right">
                    {(MyBalance[2] / 1000000000000000000).toFixed(6)}
                  </div>
                </div>
                <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="ew" />
                  </div>
                  <div className="flex-content text-right">
                    {(MyBalance[3] / 1000000000000000000).toFixed(6)}
                  </div>
                </div>
              </div>
            </section>
            <section className="bg-common border round-lg px-3 py-4 mt-3">
              <h4 className="d-flex align-items-center">
                <img src={borderImg} alt="" />
                <span className="ml-3">
                  <FormattedMessage id="earning" />
                </span>
              </h4>
              <div className="mt-4">
                <div className="flex-item align-items-center">
                  <div className="flex-content color-gray">
                    <FormattedMessage id="periods" />
                  </div>
                  <div className="flex-label">
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      options={RoundListInfo}
                      onChange={showRoundInfo}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <div className="flex-item">
                    <div className="flex-label color-gray">
                      <FormattedMessage id="periodsStatus" />
                    </div>
                    <div className="flex-content text-right">{GameStatus}</div>
                  </div>
                </div>
                <div className="mt-3">
                  {/* <div className="flex-item">
                    <div className="flex-label color-gray">
                      <FormattedMessage id="income" />
                    </div>
                    <div className="flex-content text-right">{(IncomeInfo[1].toString() / 1000000000000000000).toFixed(3)} EBW / {(IncomeInfo[0].toString() / 1000000).toFixed(3)} USDT</div>
                  </div> */}
                  <div className="flex-item">
                    <div className="flex-content color-gray">
                      <FormattedMessage id="income" />
                    </div>
                    <div className="flex-label pl-2">
                      {(IncomeInfo[1].toString() / 1000000000000000000).toFixed(
                        3
                      )}{" "}
                      EBW / {(IncomeInfo[0].toString() / 1000000).toFixed(3)}{" "}
                      USDT
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="flex-item">
                    <div className="flex-label color-gray">
                      <FormattedMessage id="pledge" />
                    </div>
                    <div className="flex-content text-right">
                      {new Decimal(IncomeInfo[2].toString())
                        .div(new Decimal("1000000000000000000"))
                        .toFixed(6)}{" "}
                      EW
                    </div>
                  </div>
                  <div className="mt-2-5">
                    <Button
                      className="ant-btn-primary w-100"
                      loading={receivePledgeRoundsLoading}
                      onClick={async () => {
                        receivePledgeRounds();
                      }}
                    >
                      <FormattedMessage id="receive" />
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="flex-item">
                    <div className="flex-label color-gray">
                      <FormattedMessage id="limit" />
                    </div>
                    <div className="flex-content text-right">
                      {(IncomeInfo[4].toString() / 1000000000000000000).toFixed(
                        3
                      )}{" "}
                      EH / {(IncomeInfo[3].toString() / 1000000).toFixed(3)}{" "}
                      USDT
                    </div>
                  </div>
                  <div className="mt-2-5">
                    <Button
                      className="ant-btn-primary w-100"
                      loading={GetRoundsToMarginCallValueLoading}
                      onClick={async () => {
                        GetRoundsToMarginCallValue();
                      }}
                    >
                      <FormattedMessage id="receive" />
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="flex-item">
                    <div className="flex-label color-gray">
                      <FormattedMessage id="recovery" />
                    </div>
                    <div className="flex-content text-right">
                      {(IncomeInfo[5].toString() / 1000000).toFixed(3)} USDT
                    </div>
                  </div>

                  <div className="mt-2-5">
                    <Button
                      className="ant-btn-primary w-100"
                      loading={ReceiveBaseIncomeLoading}
                      onClick={async () => {
                        ReceiveBaseIncome();
                      }}
                    >
                      <FormattedMessage id="receive" />
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="flex-item">
                    <div className="flex-label color-gray">
                      <FormattedMessage id="extra" />
                    </div>
                    <div className="flex-content text-right">
                      {(IncomeInfo[6].toString() / 1000000).toFixed(3)} USDT
                    </div>
                  </div>
                  <div className="mt-2-5">
                    <Button
                      className="ant-btn-primary w-100"
                      loading={ReceiveAdditionalIncomeLoading}
                      onClick={async () => {
                        ReceiveAdditionalIncome();
                      }}
                    >
                      <FormattedMessage id="receive" />
                    </Button>
                  </div>
                </div>
              </div>
            </section>
            <section className="bg-common border round-lg px-3 py-4 mt-3">
              <h4 className="d-flex align-items-center">
                <img src={borderImg} alt="" />
                <span className="ml-3">
                  <FormattedMessage id="invite" />
                </span>
              </h4>
              <div className="mt-4">
                <div>
                  <div className="flex-item align-items-center">
                    <div className="flex-label color-gray">
                      <FormattedMessage id="invitedAddress" />
                    </div>
                    <div className="flex-content ml-3">
                      <Input
                        className="text-right"
                        value={BindRefAddr}
                        disabled={BindRefDisabled}
                        onChange={async (event) => {
                          setBindRefAddr(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-2-5">
                    <Button
                      className="ant-btn-primary w-100"
                      loading={BindRefLoading}
                      disabled={BindRefDisabled}
                      onClick={async () => {
                        BindRef(BindRefAddr);
                      }}
                    >
                      <FormattedMessage id="bind" />
                    </Button>
                  </div>
                </div>
                {/* 
                <div className="mt-3">
                  <div className="flex-item align-items-center">
                    <div className="flex-label color-gray">
                      <FormattedMessage id="invitingAddress" />
                    </div>
                    <div className="flex-content ml-3">
                      <Input
                        className="text-right"
                        disabled
                        value={RefURL}
                      />
                    </div>
                  </div>
                 
                  <div className="mt-2-5">
                    <Button className="ant-btn-success w-100" onClick={() => {
                      copyCot(RefURL);
                    }}>
                      <FormattedMessage id="copy" />
                    </Button>
                  </div>
              
                </div>
                  */}

                <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="NumberOfPeopleInvited" />
                  </div>
                  <div className="flex-content text-right">
                    {SubordinateList.length}
                  </div>
                </div>
                <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="refIncome" />
                  </div>
                  <div className="flex-content text-right">
                    {(MyBalance[4] / 1000000).toFixed(3)}
                  </div>
                </div>
                <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="static" />
                  </div>
                  <div className="flex-content text-right">
                    {(UserInfos[2].toString() / 1000000).toFixed(3)}
                  </div>
                </div>
                {/* <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="dynamic" />
                  </div>
                  <div className="flex-content text-right">{(UserInfos[1].toString() / 1000000).toFixed(3)}</div>
                </div> */}
                <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="team" />
                  </div>
                  <div className="flex-content text-right">
                    <Tag color="#10CE52">{UserInfos[3].toString()}</Tag>
                  </div>
                </div>
                <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="teamReward" />
                  </div>
                  <div className="flex-content text-right">
                    {(UserInfos[4].toString() / 1000000).toFixed(3)}
                  </div>
                </div>
                <div className="flex-item mt-3">
                  <div className="flex-label color-gray">
                    <FormattedMessage id="TeamDividend" />
                  </div>
                  <div className="flex-content text-right">
                    {(MyBalance[5] / 1000000).toFixed(3)}
                  </div>
                </div>
                {/* <div className="mt-3">
                  <div className="flex-item">
                    <div className="flex-label color-gray">
                      <FormattedMessage id="address" />
                    </div>
                  </div>
                  <div className="mt-2-5 py-3 bg-dark round-md">
                    <ul className="text-center">
                      {
                        SubordinateList.map((item, index) => {
                          <li className="mt-2-5" >{item}</li>
                        })
                      }

                      
                    </ul>
                  </div>
                </div> */}
              </div>
            </section>
          </main>
        </div>
      </IntlProvider>
    </ConfigProvider>
  );
}
